/**
 * Button Component
 * Simple component to have consistent Button Styles and functionality
 */

import * as React from "react";

import "./Button.scss";

interface ButtonProps {
  className?: string;
  onClick?: React.ReactEventHandler;
  children: React.ReactNode;
}

export default class Button extends React.Component<ButtonProps> {
  render() {
    return (
      <div
        className={`button ${ this.props.className || "" }`}
        onClick={ this.props.onClick }
      >
        { this.props.children }
      </div>
    );
  }
}
