/**
 * Header Component for Landing Page.
 */

import * as React from "react";

// Components
import Icon from "../../Icon/Icon";

// Stylesheets
import "./Header.scss";

// Images
import Logo from "../../../assets/images/logo/NetzeBW_Logo_WeisOrange_sRGB 1.svg";


interface HeaderProps {
  className?: string;
}

export default class Header extends React.Component<HeaderProps> {

  constructor(props: HeaderProps) {
    super(props);
  }

  render() {
    return (
      <div className={`headerLandingpage ${ this.props.className || "" }`}>
        <div className="logo" >
          <img src={ Logo } />
        </div>
      </div>
    );
  }
}
