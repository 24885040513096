export enum POI {
  EMPTY = -1,
  POILIST = 0,
  POIOne = 1,
  POITwo = 2,
  POIThree = 3,
  POIFour = 4,
  POIFive = 5,
  POISix = 6,
  POISeven = 7,
  POIEight = 8,
  POINine = 9,
  POITen = 10,
}

// Enum that represents different device sizes for desktop and mobile support.
export enum BREAKPOINTS {
  SM = "sm",
  MD = "md",
  LG = "lg"
}
