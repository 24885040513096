/**
 * Route displaying the Landing Page for the Application.
 */

import * as React from "react";
import { CSSTransition } from "react-transition-group";

// Components
import Header from "~Components/LandingPage/Header/Header";
import LandingPageFooter from "~Components/LandingPage/LandingPageFooter/LandingPageFooter";
import ContainerOverlay from "~Components/LandingPage/ContainerOverlay/ContainerOverlay";

// Stylesheets
import "./LandingPage.scss";

// Images
import Background from "~Assets/images/landing_page/background.jpg";
import SelectionImage1 from "~Assets/images/landing_page/labore-1.jpg";
import SelectionImage2 from "~Assets/images/landing_page/labore-4.jpg";
import SelectionImage3 from "~Assets/images/landing_page/labore-3.jpg";

type StateProps = {
  overlay1: boolean;
  overlay2: boolean;
  overlay3: boolean;
}

export default class LandingPage extends React.Component<Record<string, never>, StateProps> {
  constructor(props: Record<string, never>) {
    super(props);

    this.state = {
      overlay1: false,
      overlay2: false,
      overlay3: false,
    };
  }

  componentDidMount(): void {
    document.body.classList.add("landingPageBody");
  }

  componentWillUnmount(): void {
    document.body.classList.remove("landingPageBody");
    document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className="landingPage" style={{backgroundImage: `url(${Background})` }}>
        <Header />
        <div className="contentArea">
          <div className="infoSection">
            <div className="left">
              <h1><b>Virtuelle Touren</b><br />durch die NETZlabore</h1>
              <p>Wie sich Elektromobilität auf unser Stromnetz auswirkt und wie innovative Lösungen die Netzintegration ermöglichen, testen wir in unseren NETZlaboren unter realen Bedingungen. </p>
            </div>
            <div className="right">
              <h2><b>Erleben Sie unsere NETZlabore hautnah in interaktiven 3D-Touren!</b></h2>
            </div>
          </div>
          <div className="selection">
            <div className="selection__container">
              <div className="selection__image"
                onMouseEnter={ () => this.setState({overlay1: true}) }
                onMouseLeave={ () => this.setState({overlay1: false}) }
              >
                <CSSTransition
                  in={this.state.overlay1}
                  timeout={500}
                  classNames='fade'
                  unmountOnExit={true}
                >
                  <ContainerOverlay
                    url='/Carre'
                  />
                </CSSTransition>

                <img src={SelectionImage1} />
              </div>
              <div className="selection__info">
                <h2>E-Mobility-Carré</h2>
                <p>Wie der Netzanschluss eines Mehrfamilienhauses beschaffen sein muss, wenn künftig viele E-Fahrzeuge gleichzeitig in einer gemeinsam genutzten Tiefgarage geladen werden, untersuchen wir im NETZlabor E-Mobility-Carré.</p>
              </div>
            </div>
            <div className="selection__container">
              <div className="selection__image"
                onMouseEnter={ () => this.setState({overlay2: true}) }
                onMouseLeave={ () => this.setState({overlay2: false}) }
              >
                <CSSTransition
                  in={this.state.overlay2}
                  timeout={500}
                  classNames='fade'
                  unmountOnExit={true}
                >
                  <ContainerOverlay
                    url='/Chaussee'
                  />
                </CSSTransition>

                <img src={SelectionImage2} />
              </div>
              <div className="selection__info">
                <h2>E-Mobility-Chaussee</h2>
                <p>Wie wirkt sich die steigende Anzahl von E-Fahrzeugen auf das Verteilnetz in ländlichen Gebieten aus? Für das Stromnetz ist das eine große Herausforderung. Hier sind die Stromkreise deutlich länger als in städtischen Gebieten.</p>
              </div>
            </div>
            <div className="selection__container">
              <div className="selection__image"
                onMouseEnter={ () => this.setState({overlay3: true}) }
                onMouseLeave={ () => this.setState({overlay3: false}) }
              >
                <CSSTransition
                  in={this.state.overlay3}
                  timeout={500}
                  classNames='fade'
                  unmountOnExit={true}
                >
                  <ContainerOverlay
                    url='/Nihl'
                  />
                </CSSTransition>

                <img src={SelectionImage3} />
              </div>
              <div className="selection__info">
                <h2>Intelligentes Heimladen</h2>
                <p>Im NETZlabor Intelligentes Heimladen entwickeln wir ein intelligentes  Lademanagementsystem und erproben es an verschiedenen Standorten. Unser Ziel: Eine standardisierte Steuerung der Ladeeinrichtungen, um Netzengpässe zu vermeiden.</p>
              </div>
            </div>
          </div>
        </div>
        <LandingPageFooter />
      </div>
    );
  }
}
